import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"


const Header = ({ siteTitle, menuLinks }) => (
  <nav style={{ display: 'flex', flex: 1 }}>
    <header class="header">
    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
      <ul class="menu">
      <li><a href="/">Home</a>
      </li>
      <li><a href="/faq">FAQ</a>
      </li>
      <li>
        <a href="https://shopkt8merch.com/collections/rock-the-rink">Online Store</a>
        </li>
      </ul>
      <img src="https://www.facebook.com/tr?id={1074079142784327}&amp;ev=PageView" height="1" width="1"/>

    </header>
  </nav>
)
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
